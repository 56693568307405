<template>
  <g>
    <svg:style>
      .inox-top,
      .inox-bottom,
      .inox-1,
      .inox-2,
      .inox-3 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .glass-top{
        fill: url(#glass-pattern);
      }
      .glass-bottom{
        fill: url(#glass-pattern);
      }
      .inox-top{
        fill: url(#inox-gradient-2);
      }
      .inox-bottom{
        fill: url(#inox-gradient);
      }
      .inox-1{
        fill: url(#inox-gradient);
      }
      .inox-2{
        fill: url(#inox-gradient);
      }
      .inox-3{
        fill: url(#inox-gradient);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 386.34"
                    :y1="doorTopHeight1 + 2430.55"
                    :x2="doorLeftWidth1 + 386.34"
                    :y2="doorTopHeight1 + 2339.43"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 4921.14)"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth1 + 385.98"
                    :y1="doorTopHeight1 + 2460.13"
                    :x2="doorLeftWidth1 + 385.98"
                    :y2="doorTopHeight1 + 2369.01"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-3"
                    :x1="doorLeftWidth1 + 71.2"
                    :y1="doorTopHeight1 + 144.32"
                    :x2="doorLeftWidth1 + 71.2"
                    :y2="doorTopHeight1 + 140.95"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-4"
                    :x1="doorLeftWidth1 + 71.2"
                    :y1="doorTopHeight1 + 161.35"
                    :x2="doorLeftWidth1 + 71.2"
                    :y2="doorTopHeight1 + 157.97"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlink:href="#inox-gradient"/>

    <linearGradient id="inox-gradient-5"
                    :x1="doorLeftWidth1 + 71.2"
                    :y1="doorTopHeight1 + 152.83"
                    :x2="doorLeftWidth1 + 71.2"
                    :y2="doorTopHeight1 + 149.46"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlink:href="#inox-gradient"/>

    <g id="R03">
      <path id="inox_top"
            v-if="showInox"
            data-name="inox"
            class="inox-top"
            :d="`
              M ${doorLeftWidth1 + 383.13} , ${doorTopHeight1 + 2460.13}
              A 253.17 , 253.17 , 0 , 0 , 0 , ${doorLeftWidth1 + 358.28} , ${doorTopHeight1 + 2369}
              h 33.65
              c 12.4 , 26.31 , 19.75 , 57.28 , 21.74 , 91.12
              H ${doorLeftWidth1 + 383.13} Z`"
            transform="translate(-311.75 -2324.21)"/>

      <path id="inox_bottom"
            v-if="showInox"
            data-name="inox"
            class="inox-bottom"
            :d="`
              M ${doorLeftWidth1 + 383.5} , ${doorTopHeight1 + 2490.59}
              a 253.17 , 253.17 , 0 , 0 , 1 -24.85 , 91.12
              h 33.65
              c 12.4 -26.31 , 19.75 -57.28 , 21.74 -91.12
              H ${doorLeftWidth1 + 383.5} Z
            `"
            transform="translate(-311.75 -2324.21)"/>
      <path id="glass_top"
            data-name="glass"
            class="glass-top"
            :d="`
              M ${doorLeftWidth1 + 407.37} , ${doorTopHeight1 + 2455.56}
              c -2.2 -29.9 -8.64 -57.45 -19.1 -81.41
              H ${doorLeftWidth1 + 366.7}
              a 253 , 253 , 0 , 0 , 1 , 22.05 , 81.41
              h 18.62 Z
              `"
            transform="translate(-311.75 -2324.21)"/>

      <path id="glass_bottom"
            data-name="glass"
            class="glass-bottom"
            :d="`
              M ${doorLeftWidth1 + 407.73} , ${doorTopHeight1 + 2495.15}
              c -2.2 , 29.9 -8.64 , 57.45 -19.1 , 81.41
              H ${doorLeftWidth1 + 367.06}
              a 253 , 253 , 0 , 0 , 0 , 22.05 -81.41
              h 18.62 Z
              `"
            transform="translate(-311.75 -2324.21)"/>

      <rect id="inox-1"
            v-if="showInox"
            data-name="inox"
            class="inox-1"
            :x="doorLeftWidth1 + 40"
            :y="doorTopHeight1 + 140"
            width="61.76"
            height="3.37"/>

      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="inox-2"
            :x="doorLeftWidth1 + 40"
            :y="doorTopHeight1 + 149"
            width="61.76"
            height="3.37"/>

      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="inox-3"
            :x="doorLeftWidth1 + 40"
            :y="doorTopHeight1 + 157"
            width="61.76"
            height="3.37"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
